<style lang="scss" scoped>
</style>
<template>
  <div>
    <el-tree
      :props="state.defaultProps"
      show-checkbox
      node-key="id"
      default-expand-all
      expand-on-click-node
      @check="handleCheckChange"
      :data="store.state.app.allMenuList"
      :render-content="renderContent"
      :default-checked-keys="state.default_checked_keys"
      :ref="(el) => (state.refTree = el)"
    >
    </el-tree>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, watch, nextTick } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    userInfo: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      count: 1,
      defaultProps: {
        children: "children",
        label: "menu_name",
      },
      default_checked_keys: [],
      refTree: "",
    });

    const checkedKeys = [];
    store.state.app.allMenuList.forEach((item) => {
      if (item.children.length !== 0) {
        item.children.forEach((val) => {
          checkedKeys.push(val.id);
        });
      } else {
        checkedKeys.push(item.id);
      }
    });

    if (props.userInfo.length == 0) {
      emit("se_checkedKeys", checkedKeys);
    } else {
      emit("se_checkedKeys", props.userInfo);
    }

    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http } = proxy;

    if (props.userInfo.length !== 0) {
      state.default_checked_keys = props.userInfo;
    } else {
      const arr = [];
      store.state.app.allMenuList.forEach((item) => {
        arr.push(item.id);
      });
      state.default_checked_keys = arr;
      console.log(arr);
    }

    watch(
      () => props.userInfo,
      (val, old) => {
        if (val.length !== 0) {
          nextTick(() => {
            state.refTree.setCheckedKeys([]);
            state.default_checked_keys = val;
          });
        } else {
          const arr = [];
          store.state.app.allMenuList.forEach((item) => {
            arr.push(item.id);
          });
          nextTick(() => {
            state.refTree.setCheckedKeys([]);
            state.default_checked_keys = arr;
          });
        }
      }
    );

    // methods
    const handleCheckChange = (data, checked) => {
      emit("se_checkedKeys", checked.checkedKeys);
    };

    const loadNode = (node, resolve) => {
      if (node.level === 0) {
        return resolve(store.state.app.allMenuList);
      }
      if (node.level > 0) {
        if (node.data.children.length === 0) return resolve([]);
        return resolve(node.data.children);
      }
    };

    const renderContent = (h, { node, data, store }) => {
      return h("span", null, node.label);
    };

    return {
      state,
      loadNode,
      handleCheckChange,
      renderContent,
      store,
    };
  },
};
</script>
