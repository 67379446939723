<style lang="scss" scoped>
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- 條件刪選 -->
    <collapse @seach="state.seach" />
    <div class="width_100 table-header sizing flex_lr_m">
      <div>
        <i class="el-icon-s-grid pad20" style="color: #999"></i>
        <el-button type="primary" size="mini" @click="addOpen">新增</el-button>
        <el-button
          type="danger"
          size="mini"
          @click="del_accout"
          :disabled="state.delAccountId.length === 0"
          >批量刪除</el-button
        >
      </div>
      <div class="refresh sizing height_100 flex_c_m" @click="refresh">
        <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.tableData"
      height="100%"
      border
      style="width: 100%"
      class="flex1"
      @selection-change="selection_change"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="id" label="用戶id" min-width="70">
      </el-table-column>
      <el-table-column prop="username" label="用戶名" min-width="70">
      </el-table-column>
      <el-table-column prop="email" label="電郵" min-width="50">
      </el-table-column>
      <el-table-column prop="phone" label="電話" min-width="50">
      </el-table-column>
      <el-table-column prop="status" label="狀態" min-width="50">
        <template #default="scope">
          <span>{{ scope.row.status ? "正常" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="創建時間" min-width="80">
      </el-table-column>
      <el-table-column prop="updated_at" label="修改時間" min-width="80">
      </el-table-column>
      <el-table-column
        prop="member_name"
        label="操作"
        width="150px"
        fixed="right"
      >
        <template #default="scope">
          <div class="operation height_100 flex_c_m">
            <el-button type="primary" size="mini" @click="editInfo(scope.row)"
              >編輯</el-button
            >
            <el-button type="danger" size="mini" @click="deleteInfo(scope.row)"
              >刪除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex_m_r pain pad20">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 新增彈框 -->
    <el-dialog
      title="新增管理員"
      v-model="state.dialogFormVisible"
      width="600px"
    >
      <el-form
        :model="state.form"
        :ref="(el) => (state.addFrom = el)"
        :rules="state.addRules"
      >
        <div class="flex_lr_m" style="flex-wrap: wrap">
          <el-form-item
            label="用戶名"
            :label-width="state.formLabelWidth"
            prop="name"
          >
            <el-input
              v-model="state.form.name"
              autocomplete="off"
              placeholder="請輸入用戶名"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密碼"
            :label-width="state.formLabelWidth"
            prop="pass"
            v-if="state.show_pass"
          >
            <el-input
              v-model="state.form.pass"
              autocomplete="off"
              placeholder="請輸入密碼"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="電郵"
            :label-width="state.formLabelWidth"
            prop="email"
          >
            <el-input
              v-model="state.form.email"
              autocomplete="off"
              placeholder="請輸入電郵"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="電話"
            :label-width="state.formLabelWidth"
            prop="phone"
          >
            <el-input
              v-model="state.form.phone"
              autocomplete="off"
              placeholder="請輸入電話"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="狀態"
            :label-width="state.formLabelWidth"
            prop="region"
          >
            <el-select v-model="state.form.region" placeholder="">
              <el-option label="正常" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <tree
          @se_checkedKeys="state.se_checkedKeys"
          :userInfo="state.userInfo"
        />
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="add_submit">確 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定刪除嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import Collapse from "./components/collapse";
import Tree from "./components/tree";

export default {
  components: {
    Collapse,
    Tree,
  },
  setup() {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message, $utils } = proxy;
    const store = useStore();

    const req_table_data = () => {
      $http.getAdminsList(state.user).then((res) => {
        if (res.status !== 200) return;
        state.tableData = res.data.data;
        state.count = res.data.count;
      });
    };

    // 表单验证
    const validatePass = (rule, value, callback) => {
      // 密碼正則校驗
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(value)) {
        callback(new Error("密碼至少包含數字和英文,6-18個字符"));
      } else {
        callback();
      }
    };

    const validateEmail = (rule, value, callback) => {
      // 郵箱正則校驗
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          value
        )
      ) {
        callback(new Error("請輸入正確的電郵"));
      } else {
        callback();
      }
    };

    const validatePhone = (rule, value, callback) => {
      // 手機號碼正則校驗
      if (
        !(
          /^([5|6|8|9|2|3])\d{7}$/.test(value) ||
          /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/.test(
            value
          )
        )
      ) {
        callback(new Error("請輸入正確的號碼"));
      } else {
        callback();
      }
    };

    const state = reactive({
      tableData: [],
      user: {
        limit: 10,
        page: 1,
        username: "",
        status: "",
        id: $utils.getCookie("adminId"),
      },
      addFrom: "",
      addRules: {
        name: [
          { required: true, message: "請輸入用戶名", trigger: "blur" },
          { min: 5, max: 18, message: "長度在5-18個字符", trigger: "blur" },
        ],
        pass: [
          { required: true, message: "請輸入密碼", trigger: "blur" },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "請輸入電郵", trigger: "blur" },
          {
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "請輸入電話", trigger: "blur" },
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        region: [{ required: true, message: "請選擇狀態", trigger: "blur" }],
      },
      count: "", // 總條數
      dialogFormVisible: false, // 新增彈出層
      formLabelWidth: "70px",
      form: {
        name: "",
        pass: "",
        email: "",
        phone: "",
        region: "1",
      },
      checkedKeys: [],
      delAccountId: [],
      delUserId: [],
      dialog: false,
      userInfo: {},
      show_pass: true,
      currId: "",
      seach(data) {
        state.user.username = data.seatch_name;
        state.user.status = data.region;
        req_table_data();
      },
      se_checkedKeys(data) {
        state.checkedKeys = data;
      },
    });
    // 生命周期
    /* created */

    req_table_data();

    // methods
    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    const refresh = () => {
      req_table_data();
    };

    const add_submit = () => {
      if (state.show_pass) {
        // 新增管理員
        state.addFrom.validate(async (valid) => {
          if (!valid) return $message.error("信息填寫有誤");
          const checkedKeysAll = [];
          store.state.app.allMenuList.forEach((item) => {
            checkedKeysAll.push(item.id);
            if (item.children.length !== 0) {
              item.children.forEach((val) => {
                checkedKeysAll.push(val.id);
              });
            }
          });
          const checkedKeys = checkedKeysAll.map((item) => {
            item = {
              menu_id: item,
              status: Number(state.checkedKeys.includes(item)),
            };

            return item;
          });

          const res = await $http.addAdmins({
            data: {
              email: state.form.email,
              password: state.form.pass,
              phone: state.form.phone,
              status: state.form.region,
              username: state.form.name,
            },
            power: checkedKeys,
          });
          if (res.status !== 200) return;
          $message.success("新增成功");
          req_table_data();
          state.dialogFormVisible = false;
        });
      } else {
        // 修改管理員
        state.addFrom.validate(async (valid) => {
          if (!valid) return $message.error("信息填寫有誤");
          const checkedKeysAll = [];
          store.state.app.allMenuList.forEach((item) => {
            checkedKeysAll.push(item.id);
            if (item.children.length !== 0) {
              item.children.forEach((val) => {
                checkedKeysAll.push(val.id);
              });
            }
          });
          const checkedKeys = checkedKeysAll.map((item) => {
            item = {
              menu_id: item,
              status: Number(state.checkedKeys.includes(item)),
            };

            return item;
          });
          const res = await $http.updateAdmin({
            data: {
              email: state.form.email,
              id: state.currId,
              phone: state.form.phone,
              status: state.form.region,
              username: state.form.name,
            },
            power: checkedKeys,
          });
          if (res.status !== 200) return;
          $message.success("修改成功");
          req_table_data();
          state.dialogFormVisible = false;
        });
      }
    };

    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    const dels_confirm = async () => {
      const res = await $http.delAdmins({
        id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
      state.dialog = false;
    };

    const editInfo = async (data) => {
      state.form.name = data.username;
      state.form.email = data.email;
      state.form.phone = data.phone;
      state.form.region = "" + data.status;
      state.currId = data.id;
      state.show_pass = false;
      const res = await $http.getAdminArticle({ username: data.username });
      if (res.status !== 200) return;
      const menu = Object.values(res.data);
      menu.forEach((item) => {
        item.children = Object.values(item.children);
      });
      const checkedKeys = [];
      menu.forEach((item) => {
        if (item.children.length !== 0) {
          item.children.forEach((val) => {
            if (val.status != 0) {
              checkedKeys.push(val.id);
            }
          });
        } else {
          if (item.status != 0) {
            checkedKeys.push(item.id);
          }
        }
      });
      state.userInfo = checkedKeys;
      state.dialogFormVisible = true;
    };

    const addOpen = () => {
      state.form.name = "";
      state.form.email = "";
      state.form.phone = "";
      state.form.region = "";
      state.show_pass = true;
      state.userInfo = [];
      state.dialogFormVisible = true;
    };

    // 刪除管理員
    const deleteInfo = (data) => {
      state.dialog = true;
      state.delUserId = [data.id];
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      add_submit,
      selection_change,
      del_accout,
      dels_confirm,
      editInfo,
      addOpen,
      deleteInfo,
    };
  },
};
</script>
